<template>
  <div
    style="
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 30px;
    "
  >
    <h5 style="margin-top: 30px">Hotels Statistics</h5>
    <VueBarGraph
      :points="labelsHotel"
      :width="1000"
      :height="500"
      :show-values="true"
      :show-y-axis="true"
      :show-x-axis="true"
    >
    </VueBarGraph>
    <h5 style="margin-top: 30px">User Statistics</h5>
    <VueBarGraph
      :points="labelsUser"
      :width="1000"
      :height="500"
      :show-values="true"
      :show-y-axis="true"
      :show-x-axis="true"
    >
    </VueBarGraph>
  </div>
</template>

<script>
import VueBarGraph from "vue-bar-graph";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    VueBarGraph,
  },
  name: "Statistics",
  data() {
    return {
      labelsHotel: [],
      labelsUser: [],
    };
  },
  computed: {
    ...mapGetters("hotelModule", ["getStatistics"]),
    ...mapGetters("userModule", ["getUserStatistics"]),
  },
  methods: {
    ...mapActions("hotelModule", ["getHotelStatistics"]),
    ...mapActions("userModule", ["getStatisticsUser"]),
  },
  async mounted() {
    await this.getHotelStatistics();
    await this.getStatisticsUser();
    for (let i in this.getStatistics) {
      let date = i.split("-");
      this.labelsHotel.push({
        label: `${date[2]}.${date[1]}`,
        value: this.getStatistics[i],
      });
    }
    for (let i in this.getUserStatistics) {
      let date = i.split("-");
      this.labelsUser.push({
        label: `${date[2]}.${date[1]}`,
        value: this.getUserStatistics[i],
      });
    }
  },
};
</script>

<style scoped></style>
